import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Link from '../../components/Link'
import styles from './work.module.scss'

const title = "Improving students' performance"
const description =
  'The Department of Behavioral Economics at UCSD and CMU engaged with us to understand how to automate collection and processing of data for a research project in time for the Spring semester kick off.'
const image = 'https://www.zero.london/case-ucsd@3x.png'
const imageAlt = 'UCSD and CMU mobile app'
const imageDimensions = [2754, 1596]

export default ({ location }) => (
  <Layout location={location} back>
    <Helmet>
      <title>Improving students' performance</title>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:image:alt' content={imageAlt} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:alt' content={imageAlt} />
      <meta property='og:image:width' content={imageDimensions[0]} />
      <meta property='og:image:height' content={imageDimensions[1]} />
    </Helmet>
    <div className={styles.Case}>
      <div className={styles.Intro}>
        <div className={styles.Screenshots}>
          <div className={styles.ImageUniversityCalifornia}>
            <img
              className={styles.desktop}
              alt='Manatee mobile app screenshots'
              srcSet={`
                /case-ucsd.png 1x,
                /case-ucsd@2x.png 2x,
                /case-ucsd@3x.png 3x
              `.replace(/\n/g, ' ')}
            />
            <img
              className={styles.mobile}
              alt='Manatee mobile app screenshots'
              srcSet={`
                /ucsd-cmu-sleep.png 1x,
                /ucsd-cmu-sleep@2x.png 2x,
                /ucsd-cmu-sleep@3x.png 3x
              `.replace(/\n/g, ' ')}
            />
          </div>
        </div>
        <aside>University of California and Carnegie Mellon</aside>
        <h1>Improving students' performance</h1>
      </div>

      <div className={styles.Content}>
        <div className={styles.Description}>
          <h2>Challenge</h2>
          <p>
            The Department of Behavioral Economics at UCSD and CMU engaged with us to understand how to automate
            collection and processing of data for a research project in time for the Spring semester kick off.
          </p>
          <p>
            We partnered to define, design, build and launch a mobile application across both iOS and Android for the
            research study with the goal of helping students to improve their academic performance.
          </p>
          <p>
            The app syncs and leverages data from Fitbit API generated by students. This enabled us to deliver sleep
            reminders and rewards based on a set of configurable parameters. We also implemented a multi-variant test
            mechanic to see how different students react to different types of rewards.
          </p>
          <h2>Results</h2>
          <p>
            In a short time frame, we enabled the universities to onboard students, and set up a solid foundation to run
            the study for a period of 3 years, as well as collect and process data generated at scale.
          </p>
          <p>In addition, we provided monitoring and support to ensure smooth operations.</p>
        </div>

        <div className={styles.Specs}>
          <h3>Deliverables</h3>
          <p className={styles.small}>
            Definition, Design, API development, UI development, iOS and Android distribution
          </p>

          <h3>Tech Stack</h3>
          <p className={styles.small}>React Native, Node.js, Postgres, Digital Ocean</p>

          <h3>Duration</h3>
          <p className={styles.small}>4 weeks</p>
        </div>

        <div className={styles.Quote}>
          <div className={styles.Photo}>
            <img
              className={styles.ImageOne}
              alt='Silvia Saccardo'
              srcSet={`
                /saccardo-silvia.jpg 1x,
                /saccardo-silvia@2x.jpg 2x,
                /saccardo-silvia@3x.jpg 3x
              `.replace(/\n/g, ' ')}
            />
            <aside className={styles.mobile}>Silvia Saccardo – Carnegie Mellon University, Project Lead</aside>
          </div>
          <div>
            <p>
              “They thoughtfully guided us throughout the design and development process, and delivered at speed. Great
              partners. Highly recommended.”
            </p>
            <aside className={styles.desktop}>Silvia Saccardo – Carnegie Mellon University, Project Lead</aside>
          </div>
        </div>

        <div className={styles.Next}>
          <h2>Next</h2>
          <Link to='/work/manatee-app' monochrome>
            <strong>Manatee</strong> – Improving children's mental health
          </Link>
        </div>
      </div>
    </div>
    <div className={styles.Contact}>
      <p>
        Need an opinion of your project?{' '}
        <Link button small to='/contact'>
          Let’s talk
        </Link>
      </p>
    </div>
    <div className={styles.FinalSection}>
      <h4>Are you an ambitious entrepreneur or business leader?</h4>
      <Link large button to='/contact'>
        Get our view on your project
      </Link>
    </div>
  </Layout>
)
